<script>
  import Modal from '../../components/Modal'

  export default {
    name: 'edit-tasks',
    extends: Modal,
    data() {
      return {
        entity: 'tasks',
        fields: [
          {
            name: 'name',
            label: 'Название задачи',
          },
          {
            name: 'project_id',
            label: 'Проект / Сделка',
            type: 'select',
            ajax: {
              entity: 'filter/projects',
            },
          },
          {
            name: 'closed_at',
            label: 'Дата закрытия',
            type: 'date',
          },
          {
            name: 'oneExtra.td_id',
            label: 'TimeDoctor ID задачи',
          },
          {
            name: 'oneExtra.asana_task_id',
            label: 'Asana ID задачи',
          },
        ],
      }
    },
  }
</script>
