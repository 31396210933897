<script>
  import api from '../../assets/js/api'
  import Modal from '../../components/Modal'

  export default {
    name: 'edit-projects',
    extends: Modal,
    data() {
      return {
        entity: 'projects',
        _repositories: [],
        fields: [
          {
            name: 'name',
            label: 'Название проекта',
          },
          {
            name: 'client_id',
            label: 'Клиент',
            type: 'select',
            ajax: {
              entity: 'clients',
            },
          },
          {
            name: 'repository_id',
            label: 'Репозиторий',
            type: 'select',
            items: [],
          },
          {
            name: 'followers',
            label: 'Участники',
            type: 'select',
            multiple: true,
            items: [],
            customValue(source) {
              return (
                (source &&
                  source.followers &&
                  source.followers.map(follower => {
                    return follower.id
                  })) ||
                []
              )
            },
          },
          {
            name: 'bonus_coeff',
            label: 'Премиальный коэффициент',
          },
          {
            name: 'oneExtra.td_id',
            label: 'TimeDoctor ID проекта',
          },
          {
            name: 'oneExtra.asana_task_id',
            label: 'Asana ID проекта',
          },
        ],
      }
    },
    created() {
      api.base
        .get({
          entity: 'users',
        })
        .then(result => {
          let find = this.fields.find(elem => {
            return elem.name === 'followers'
          })

          if (find) {
            find.items = result.items.map(item => {
              return {
                name: item.name,
                value: item.id,
              }
            })
          }
        })

      api.base
        .get({
          entity: 'repositories',
        })
        .then(result => {
          let find = this.fields.find(elem => {
            return elem.name === 'repository_id'
          })

          find.items = result.items.map(item => {
            return {
              name: item.name,
              value: item.id,
            }
          })
        })
    },
  }
</script>
