<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <b-table
        class="edge"
        :fields="table.fields"
        :items="rows"
        :sort-by="data.sort.by"
        :sort-desc="data.sort.desc"
        @sort-changed="$emit('sortChange', $event)"
        :filter="filter"
        no-local-sorting
        responsive
      >
        <template slot="name" slot-scope="data">
          <expander
            :level="data.item.level"
            :has-items="hasItems(data.item)"
            :expanded="data.item._expanded"
            @expand="expand(data.item)"
          >
            <template slot="text">
              <div @click="expand(data.item)" style="display: flex; align-items: center">
                <template v-if="data.item._isEmployee">
                  <span class="employee-indicator" :class="getDotColor(getLastTouch(data.item))"></span>
                  <responsible :photo="data.item.photoLink" class="mx-10" />
                  <div>
                    <b-link :to="'/employees/' + data.item.employee_id" target="_blank" style="font-weight: 400">
                      {{ data.value }}
                    </b-link>
                    <div class="small emoloyee-status" :class="getDotColor(getLastTouch(data.item))">
                      {{ getStatusText(data.item) }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  {{ data.value }}
                </template>
              </div>
            </template>
          </expander>
        </template>

        <template v-for="type in ['started', 'ended']" :slot="type" slot-scope="data">
          <template v-if="data.item._isEmployee">
            <template v-if="data.value">
              {{ data.value | formatDate('H:mm') }}
            </template>
            <template v-else>-</template>
          </template>
        </template>

        <template slot="intervals" slot-scope="data">
          <template v-if="data.item._isEmployee">
            <template v-if="(data.item.intervals || []).length">
              <fa icon="clock" size="sm" :id="'intervals-row_' + data.index" />
              <popover :target="'intervals-row_' + data.index">
                <div>
                  <ul class="pl-15 m-0">
                    <li v-for="interval in data.item.intervals">
                      <span :class="{ 'text-warning': interval.edited }" class="mr-5">
                        {{ interval.started_at | formatDate('H:mm') }} -
                        {{ interval.completed_at | formatDate('H:mm') }}
                        ({{
                          $moment(interval.completed_at).diff(interval.started_at)
                            | formatDuration(undefined, undefined, 'milliseconds')
                        }})
                      </span>
                      <!--<b-link :to="'/projects/list'+-->
                      <!--'?filters[projects][id]=' + interval.project.id +-->
                      <!--'&filters[projects][preset]=allActiveAndArchive'"-->
                      <!--target="_blank" style="font-weight: 400">-->
                      <!--{{interval.project.name | slicer(30)}}-->
                      <!--</b-link> |-->
                      <b-link
                        :to="
                          '/tasks/list' +
                          '?filters[tasks][id]=' +
                          interval.task.id +
                          '&filters[tasks][preset]=allOpenedAndClosed'
                        "
                        target="_blank"
                      >
                        {{ interval.task.name | slicer(50) }}
                      </b-link>
                    </li>
                  </ul>
                </div>
              </popover>
            </template>
            <template v-else>-</template>
          </template>
        </template>

        <template slot="last_task" slot-scope="data">
          <template v-if="data.item._isEmployee || data.item._isSummary">
            <b-link
              v-if="data.value"
              :to="
                '/tasks/list' + '?filters[tasks][id]=' + data.value.id + '&filters[tasks][preset]=allOpenedAndClosed'
              "
              target="_blank"
              style="font-weight: 400"
            >
              {{ data.value.name | slicer(30) }}
            </b-link>
            <br />
            <b-link
              v-if="data.item.last_project"
              :to="
                '/projects/list' +
                '?filters[projects][id]=' +
                data.item.last_project.id +
                '&filters[projects][preset]=allActiveAndArchive'
              "
              target="_blank"
            >
              {{ data.item.last_project.name | slicer(30) }}
            </b-link>
          </template>
        </template>

        <template
          v-for="period in ['hours_today', 'hours_week', 'hours_month', 'hours_previous_month']"
          :slot="period"
          slot-scope="data"
        >
          <template>
            <b-link v-if="data.value" :to="linkToReport(data.item, period)" target="_blank">
              {{ data.value | formatDuration(undefined, undefined, 'hours') }}
            </b-link>
            <template v-else>-</template>
          </template>
        </template>
      </b-table>
    </template>
  </list>
</template>

<script>
  import qs from 'qs'
  import { library } from '@fortawesome/fontawesome-svg-core'
  import { faClock } from '@fortawesome/fontawesome-free-solid'

  library.add(faClock)

  import List from '../../../components/List'
  import Expander from '../../../components/Expander'

  import EditProjects from '../../../views/Projects/EditProject'
  import EditEmployees from '../../../views/Employees/EditEmployee'
  import EditTasks from '../../../views/Tasks/EditTask'
  import Responsible from '../../../components/Responsible'
  import Popover from '../../../components/PopoverAlive'

  import entityMixins from '../../../mixins/entity'
  import stairsMixins from '../../../mixins/stairs'

  export default {
    name: 'Report',
    components: {
      Responsible,
      Expander,
      List,
      EditProjects,
      EditTasks,
      EditEmployees,
      Popover,
    },
    mixins: [entityMixins, stairsMixins],
    data() {
      return {
        table: {
          fields: [
            {
              key: 'name',
              label: '&nbsp;',
            },
            {
              key: 'last_task',
              label: 'Последняя задача',
            },
            {
              key: 'intervals',
              label: 'Интервалы',
              class: 'text-center',
            },
            {
              key: 'started',
              label: 'Начало дня',
              class: 'text-center',
            },
            {
              key: 'ended',
              label: 'Конец дня',
              class: 'text-center',
            },
            {
              key: 'hours_today',
              label: 'Сегодня',
              class: 'text-center',
            },
            {
              key: 'hours_week',
              label: 'На этой неделе',
              class: 'text-center',
            },
            {
              key: 'hours_month',
              label: 'В этом месяце',
              class: 'text-center',
            },
            {
              key: 'hours_previous_month',
              label: 'В прошлом месяце',
              class: 'text-center',
            },
          ],
        },
        filters: [
          {
            name: 'date',
            type: 'date',
            label: 'Дата',
          },
        ],
      }
    },
    methods: {
      resultHandler(result) {
        let items = []

        if (result.summary) {
          result.summary._isSummary = true

          items.push(result.summary)
          items.push({ _rowVariant: 'delimiter mini-delimiter' })
        }

        result.data.forEach(department => {
          Object.assign(department, {
            _isDepartment: true,
            _expanded: true,
          })

          department.items.forEach(employee => {
            employee._isEmployee = true
          })

          items.push(department)
          items.push({ _rowVariant: 'delimiter mini-delimiter' })
        })

        result.items = items

        this.dataPrepare(result)
      },
      getLastTouch(employee) {
        return employee['last_time'] || null
      },
      getDotColor(date) {
        date = moment(date)

        if (!date._isValid) {
          return { danger: true }
        }

        let diff = moment().diff(date, 'minutes')

        if (diff < 5) {
          return { ok: true }
        } else if (diff >= 5 && diff < 2880) {
          return { warning: true }
        } else {
          return { danger: true }
        }
      },
      getStatusText(employee) {
        let date = moment(this.getLastTouch(employee))

        if (!date._isValid) {
          return 'Нет информации'
        }

        let color = this.getDotColor(this.getLastTouch(employee))

        if (color.ok) {
          return 'Сейчас работает'
        } else {
          return `Работал ${date.fromNow()}`
        }
      },
      linkToReport(item, period) {
        let path = 'reports/clients'
        let params = {}

        if (item._isEmployee) {
          params.users = `user_${item.id}`
        }

        let dateFormat = 'YYYY-MM-DD'
        let now = moment().subtract(3, 'd')

        switch (period) {
          case 'hours_today':
            params.date = {
              from: now.format(dateFormat),
              to: now.format(dateFormat),
            }

            break
          case 'hours_week':
            params.date = {
              from: now.startOf('week').format(dateFormat),
              to: now.endOf('week').format(dateFormat),
            }

            break
          case 'hours_month':
            params.date = {
              from: now.startOf('month').format(dateFormat),
              to: now.endOf('month').format(dateFormat),
            }

            break
          case 'hours_previous_month':
            let previousMonth = now.subtract(1, 'month')

            params.date = {
              from: previousMonth.startOf('month').format(dateFormat),
              to: previousMonth.endOf('month').format(dateFormat),
            }

            break
        }

        return `/${path}?${qs.stringify({
          filters: {
            [path]: params,
          },
        })}`
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    td {
      vertical-align: middle !important;
    }
  }

  $danger: rgba(0, 0, 0, 0.1);
  $warning: #e4b248;
  $ok: rgba(0, 153, 0, 0.6);

  .employee-indicator {
    $size: 6px;

    width: $size;
    height: $size;
    display: inline-block;
    border-radius: 50%;

    &.danger {
      background: $danger;
    }

    &.warning {
      background: $warning;
    }

    &.ok {
      background: $ok;
    }
  }

  .emoloyee-status {
    font-weight: 400;

    &.danger {
      color: #868e96;
    }
    &.warning {
      color: $warning;
    }

    &.ok {
      color: $ok;
    }
  }
</style>
