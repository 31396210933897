<template>
  <img
    class="user-avatar"
    :src="avatar"
    :title="responsible && responsible.name"
    @error="onError($event)"
    v-b-tooltip.hover
  />
</template>

<script>
  import defaultAvatar from '../../static/img/avatars/default.jpg'

  export default {
    name: 'responsible',
    props: ['responsible', 'photo'],
    computed: {
      avatar() {
        let avatar = this.photo || this.$dotGet(this.responsible, 'photoLink', this.$dotGet(this.responsible, 'avatar'))

        let link = this.$dotGet(avatar, 'link')

        if (typeof link === 'string') {
          avatar = link
        }

        if (!avatar) {
          avatar = defaultAvatar
        }

        return avatar
      },
    },
    methods: {
      onError(e) {
        e.target.src = defaultAvatar
      },
    },
  }
</script>
